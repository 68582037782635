import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { StripeAxios, AFFILIATE_HERO_SECTION, STRAPI_IMAGE } from '../../../constants/strapi-api-constant'
import Loader from '../../../components/Loader'

const AffiliateTop = () => {
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState({})

  useEffect(() => {
    getAffiliateHeroContent()
  }, [])

  const getAffiliateHeroContent = async () => {
    try {
      setLoading(true)
      await StripeAxios.get(AFFILIATE_HERO_SECTION).then(res => {
        setLoading(false)
        const { data } = res?.data;
        setContent(data?.attributes)
      })
        .catch(err => {
          console.log("Affiliate hero section api error", err)
        });

    } catch (error) {
      setLoading(false)
      console.log("Affiliate hero section error", error);
    }
  }
  const line = content?.Subheading?.split(' - ');

  return (
    <>
      {loading && <Loader />}
      <div className='affiliateTop'>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={7}>
              <div className='affiliate_top_text'>
                <h1>{content?.Heading}</h1>
                {line?.map((Ln, n) => (
                  <h3 key={n}>{Ln}</h3>
                ))}
                <p>{content?.Description}</p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={5} className='flexCenter'>
              <div className='affiliate_top_image' style={{ background: `url(${STRAPI_IMAGE + content?.Image?.data?.attributes?.url}`, backgroundSize: "cover", backgroundPosition: 'center' }}></div>
            </Col>
          </Row>
        </Container>
      </div>
    </>

  )
}

export default AffiliateTop