import { AiOutlineStar } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import avatar from '../../../../assets/images/avatar2.png'
import React from 'react'
import { Card, Button, Image } from 'react-bootstrap'
import { STRAPI_IMAGE } from "../../../../constants/strapi-api-constant";
const ReviewCard = ({ review }) => {


  const ratingView = () => {
    let rating = [];
    for (let index = 0; index < review?.Rating || 0; index++) {
      rating.push(<li key={index}><AiFillStar /></li>);
    }
    return rating;
  }
  return (
    <div className='slide_item'>
      <Card >
        <Card.Body>
          <div className='top'>
            <div className='review_img'>
              {review?.Image?.data ?
                <Image src={STRAPI_IMAGE + review?.Image?.data?.attributes?.url} alt="user Image" />
                :
                <Image src={avatar} alt="user Image" />
              }
            </div>
            <h5>{review?.Name || ''}</h5>
            <h6>{review?.CompanyName || ''}</h6>

          </div>
          <p> {review?.Review || ''}</p>
          <ul className="review_stars">
            {ratingView()}
            {/* <li><AiFillStar /></li>
            <li><AiFillStar /></li>
            <li><AiFillStar /></li>
            <li><AiFillStar /></li>
            <li><AiOutlineStar /></li> */}
            {/* <li className="mr-3">{review?.Rating + '/5'}</li> */}
          </ul>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ReviewCard