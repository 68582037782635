import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { STRAPI_IMAGE, STRAPI_HOME_TOP, StripeAxios } from '../../../constants/strapi-api-constant';
import Loader from '../../../components/Loader';
import { useEffect, useState } from 'react';
import { Player, BigPlayButton } from 'video-react';
import { LazyLoadImage } from "react-lazy-load-image-component";
// import Placeholder from './../../../assets/images/placeholder.png';

const HomeMainHeader = () => {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getPartnerData()
    }, [])

    const getPartnerData = async () => {
        try {
            setLoading(true)
            await StripeAxios.get(STRAPI_HOME_TOP).then(res => {
                const { data } = res.data;
                setContent(data?.attributes)
                setLoading(false)
            }).catch(err => { setLoading(false); console.log('Home Top section', err) });

        } catch (error) {
            setLoading(false)
            console.log("HOme Top api error", error);
        }
    }





    return (

        <section className="main-header-front">
            {loading ? <Loader /> : ""}
            <Container>
                <Row>
                    <Col md={7}>
                        <div className="banner-content">
                            {content?.Heading ?
                                <>
                                    {content?.Heading.split('.').map((h3, h) => (
                                        <h3 key={h}>{h3}</h3>
                                    ))}
                                </>
                                : ""}

                            <p className='para-style'><strong>{content?.subheading ? content?.subheading : ''}</strong> </p>
                            <p>{content?.Description ? content?.Description : ''}</p>
                            <div className="tabs">
                                <ul>
                                    <li><Link to='/pricing-plan'>Sign Up</Link></li>
                                    <li><Link to='/skipify-u'>Watch Tutorial</Link></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={5} className='image_div_top'>
                        <div className='vedio-div'>
                            <Player
                                src={STRAPI_IMAGE + content?.video?.data?.attributes?.url}
                                poster={STRAPI_IMAGE + content?.Thumbnail?.data?.attributes?.url}

                            >
                                <BigPlayButton position="center" />
                            </Player>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Row>
                <Col md={8} lg={8} className='mx-auto text-center mb-3'>
                    {content.Image ?
                        <LazyLoadImage src={STRAPI_IMAGE + content.Image?.data?.attributes?.url}
                            PlaceholderSrc={STRAPI_IMAGE + content.Image?.data?.attributes?.url}
                            effect="blur"
                            className='img-fluid'
                            alt="skipify dashboard template"
                        />
                        :
                        ''
                    }
                </Col>
            </Row>

        </section>
    )
}

export default HomeMainHeader;