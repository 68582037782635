import { BsFillTelephoneFill } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { BiMapPin } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { SUBMIT_CONTACTUS_FORM_ENDPOINT } from "../../constants/api-routes.constant";
import { Spinner, Form, Alert, Row, Col } from "react-bootstrap";
import { BsFillExclamationCircleFill, } from "react-icons/bs";


import ReCAPTCHA from "react-google-recaptcha";
import {
  Google_reCaptcha_Site_Key,
} from "../../constants/api-credentials.constant";
import { STRAPI_CONTACT_SUPPORT, STRAPI_SUBMIT_CONTACT_SUPPORT, SEO_CONTENT_OF_CONTACT_SUPPORT, StripeAxios } from "../../constants/strapi-api-constant";
import Loader from "../../components/Loader";
import HubspotTracking from "../../components/HubspotTracking";
import Seo from "../../components/Seo";

const recaptchaRef = React.createRef();


const ContactSupport = () => {
  const [values, setValues] = useState({
    fullName: "",
    emailAddress: "",
    phoneNumber: "",
    phoneNumberWithMaking: "",
    subject: "",
    message: "",
  });
  const [fullName, setfullName] = useState('')
  const [emailAddress, setemailAddress] = useState('')
  const [phoneNumber, setphoneNumber] = useState('')
  const [subject, setsubject] = useState('')
  const [message, setmessage] = useState('')
  const [load, setLoad] = useState(false)
  const [successMessage, setSuccessMessage] = useState("");
  const [queryErr, setqueryErr] = useState('');

  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(false)

  const [metaData, setMetaData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageContent()
  }, []);

  const getPageContent = async () => {
    setLoading(true)

    try {

      const getContactSupportContent = await StripeAxios.get(STRAPI_CONTACT_SUPPORT);
      const getContactSupportSeoContent = await StripeAxios.get(SEO_CONTENT_OF_CONTACT_SUPPORT);

      const [getContactSupportSeoContentResp, getContactSupportContentResp] = await Promise.all([
        getContactSupportSeoContent,
        getContactSupportContent
      ]);
      setLoading(false)


      if (getContactSupportContentResp && getContactSupportContentResp?.data) {
        const { data } = getContactSupportContentResp?.data;
        setContent({
          BannerTitle: data.attributes.BannerTitle,
          FormHeading: data.attributes.FormHeading,
          BannerDescription: data.attributes.BannerDescription,
          FormDescription: data.attributes.FormDescription[0].children[0].text,
        })
      }

      if (getContactSupportSeoContentResp && getContactSupportSeoContentResp?.data) {

        const { data } = getContactSupportSeoContentResp?.data;
        setMetaData(data?.attributes?.Seo || {});
      }
    } catch (error) {
      setLoading(false);
      console.log("contact support Strapi Call", error)
    }
  }

  const EmailRegex = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  // submit the contact form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true)

    let errorFlag = false;
    if (!values?.fullName) {
      setfullName("Please Enter Full Name")
      setLoad(false)
      errorFlag = true;
    } else {
      setfullName("");
    }

    if (!values?.emailAddress) {
      setemailAddress("Please Enter Email Address")
      setLoad(false)
      errorFlag = true;
    } else if (!EmailRegex.test(values?.emailAddress)) {
      setemailAddress("Enter a valid email address")
      setLoad(false)
      errorFlag = true;
    } else {
      setemailAddress("");
    }

    // if (!values?.phoneNumber) {
    //   setphoneNumber("Please Enter Phone Number")
    //   setLoad(false)
    //   errorFlag = true;
    // } else {
    //   setphoneNumber("");
    // }

    // if (!values?.subject) {
    //   setsubject("Please Enter Subject")
    //   setLoad(false)
    //   errorFlag = true;
    // } else {
    //   setsubject("")
    // }

    if (!values?.message) {
      setmessage("Please Enter Message")
      setLoad(false)
      errorFlag = true;
    } else {
      setmessage("");
    }


    const token = await recaptchaRef.current.executeAsync();
    if (!token) {
      setqueryErr(`Error: Invalid captcha attempt.`);
      setLoad(false)
      return;
    } else {
      recaptchaRef.current.reset();
    }


    if (!errorFlag) {


      try {
        // All validations passed, proceed with API calls
        const submitPersonalInfoPromise = fetch(SUBMIT_CONTACTUS_FORM_ENDPOINT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName: values?.fullName,
            emailAddress: values?.emailAddress,
            phoneNumber: parseInt(values?.phoneNumber),
            message: values?.message,
            companyName: values?.companyName,
          }),
        });

        const handleCmsContentPromise = handleCmsContent();

        const [submitPersonalInfoResponse, handleCmsContentResponse] = await Promise.all([
          submitPersonalInfoPromise,
          handleCmsContentPromise,
        ]);

        if (submitPersonalInfoResponse.status === 200) {
          const submitPersonalInfoJson = await submitPersonalInfoResponse.json();
          setLoad(false);
          setSuccessMessage(submitPersonalInfoJson?.result);

          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);

          setValues({
            fullName: "",
            emailAddress: "",
            phoneNumber: "",
            phoneNumberWithMaking: "",
            subject: "",
            message: "",
          });
        }

      } catch (error) {
        setLoad(false);
        setqueryErr("Error: Invalid Username or Password provided.");
      }

    }
  };

  //submit content to CMS
  const handleCmsContent = () => {

    const cmsdata = {
      data: {
        Name: values?.fullName,
        Email: values?.emailAddress,
        Phone: parseInt(values?.phoneNumber),
        Subject: values?.subject,
        Message: values?.message,
      }
    }

    StripeAxios.post(STRAPI_SUBMIT_CONTACT_SUPPORT, cmsdata)
      .then(res => {
        setLoading(false)
        return res;
      })
      .catch(err => { setLoading(false); console.log("contact support submit message", err) })
  }

  // On change handle inputs values
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };


  // Function to remove the Masking from phone Number
  const NormalizePhoneNumber = (phone) => {
    const numericOnly = phone.replace(/\D/g, '');
    let maskedNumber = '';
    if (numericOnly.length > 0) {
      maskedNumber = `(${numericOnly.slice(0, 3)}`;
    }
    if (numericOnly.length > 3) {
      maskedNumber += `) ${numericOnly.slice(3, 6)}`;
    }
    if (numericOnly.length > 6) {
      maskedNumber += `-${numericOnly.slice(6, 10)}`;
    }
    setValues({
      ...values,
      phoneNumber: numericOnly.slice(0, 10),
      phoneNumberWithMaking: maskedNumber,
    })

  }



  return (
    <>
      <HubspotTracking />
      <Seo metaData={metaData} />
      {loading && <Loader />}
      <header className="header-inner">
        <h1>{content?.BannerTitle}</h1>
        <p>{content?.BannerDescription}</p>
      </header>
      <section className="contact-page">
        <div className="container">
          <Row >
            <Col md={12} lg={4} className="mb-3">
              <Row className="d-flex justify-content-center">
                <Col md={6} lg={12}>
                  <div className="left-contact">
                    <div className="icon "><BsFillEnvelopeFill /></div>
                    <div className="contact-box">
                      <h4>Email</h4>
                      <a href="mailto:support@skipify.ai">support@skipify.ai</a>
                      {/* <p>www.skipify.ai</p> */}
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={12}>
                  <div className="left-contact">
                    <div className="icon"> <BsFillTelephoneFill /></div>
                    <div className="contact-box">
                      <h4>Phone</h4>
                      <a href="tel:+1-414-751-0987">(414) 751-0987</a>
                    </div>
                  </div>
                </Col>
                <Col md={6} lg={12}>
                  <div className="left-contact">
                    <div className="icon"> <BiMapPin /></div>
                    <div className="contact-box">
                      <h4>Address</h4>
                      <a target="_blank" href="https://www.google.com/maps/place/Skipify.ai/@41.7966367,-88.0116752,15z/data=!4m6!3m5!1s0x880e4fdb7f0cecb9:0xd02e8a793c66b2ce!8m2!3d41.7966367!4d-88.0116752!16s%2Fg%2F11v3m7540d?entry=ttu">
                        <p>4949 Forest Ave, First FL, Downers Grove, IL 60515</p>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={10} lg={6} className="px-5  mx-auto">

              <div className="left-contact2">
                {/* <span className="top_label">Send Us Message</span> */}
                <h3 className="mb-2">{content?.FormHeading}</h3>
                <p>{content?.FormDescription}</p>
                <Row>
                  <Col md={12}>
                    {successMessage != "" ? <Alert variant="success">{successMessage} </Alert> : ""}
                    {queryErr != "" ? <Alert variant="danger">{queryErr} </Alert> : ""}
                  </Col>
                </Row>
                <form onSubmit={handleSubmit} method="POST" id="skipify_contact_support_form">
                  <p className="require_span">Fields marked with an * are required</p>
                  <Row>
                    <Col md={6}>
                      <input
                        type="text"
                        name="fullName"
                        placeholder="Full Name *"
                        id="full_name"
                        value={values?.fullName}
                        onChange={(e) => { handleChange(e); setfullName("") }}

                      />
                      {fullName ? <span className="text-danger"><BsFillExclamationCircleFill /> {fullName}</span> : ""}
                    </Col>
                    <Col md={6}>
                      <input
                        type="email"
                        name="emailAddress"
                        placeholder="Email Address *"
                        value={values?.emailAddress}
                        id="email_address"
                        onChange={(e) => { handleChange(e); setemailAddress("") }}

                      />
                      {emailAddress ? <span className="text-danger"><BsFillExclamationCircleFill /> {emailAddress}</span> : ""}
                    </Col>


                    <Col md={6}>
                      <Form.Group className="mb-2" >
                        <Form.Control
                          type="text"
                          name="phoneNumber"
                          id="phone_number"
                          placeholder="Phone # (123) 234-3453"
                          onChange={(e) => { NormalizePhoneNumber(e.target.value.replace(/\D/g, '')); setphoneNumber("") }}
                          value={values?.phoneNumberWithMaking}
                        />
                        {/* {phoneNumber ? <span className="text-danger"><BsFillExclamationCircleFill /> {phoneNumber}</span> : ""} */}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder="Subject I Would Like To Discuss"
                        value={values?.subject}
                        onChange={(e) => { handleChange(e); setsubject("") }}
                      />
                      {/* {subject ? <span className="text-danger"> <BsFillExclamationCircleFill /> {subject}</span> : ""} */}
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" rows={8}
                          value={values?.message}
                          onChange={(e) => { handleChange(e); setmessage("") }}
                          name="message"
                          id="message"
                          placeholder="Message For Support Team *"
                        />
                      </Form.Group>
                      {message ? <span className="text-danger"><BsFillExclamationCircleFill /> {message}</span> : ""}
                    </Col>

                  </Row>

                  <button type="submit" className="submit">
                    {load ? <Spinner animation="border" size="sm" /> : "Send Message"}
                  </button>
                </form>

                {/* recaptcha */}
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={Google_reCaptcha_Site_Key}
                />
              </div>
            </Col>
          </Row>
        </div>
      </section >
      {/* <section className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2974.469918207231!2d-88.01386528423023!3d41.79663632922871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4e270b556879%3A0x828d7614168cc9ec!2sPlego%20Technologies!5e0!3m2!1sen!2s!4v1679413205167!5m2!1sen!2s"
          width="100%"
          height="600"
          style={{ border: "none" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section> */}
    </>
  );
};

export default ContactSupport;
