import { BsFillQuestionCircleFill } from "react-icons/bs";
import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Accordion } from 'react-bootstrap'
import { StripeAxios, AFFILIATE_FAQS } from "../../../constants/strapi-api-constant";
import Loader from '../../../components/Loader'

const AffiliateFAQ = () => {

  const [loading, setLoading] = useState(false)
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    getAffiliateRequirementContent()
  }, [])

  const getAffiliateRequirementContent = async () => {
    try {
      setLoading(true)
      await StripeAxios.get(AFFILIATE_FAQS).then(res => {
        setLoading(false)
        const { data } = res?.data;
        setFaqs(data)
      })
        .catch(err => {
          console.log("Affiliate Requirment section api error", err)
        });

    } catch (error) {
      setLoading(false)
      console.log("Affiliate Requirment section error", error);
    }
  }


  return (
    <>
      {loading && <Loader />}
      <div className='affiliateFAQ skybg'>
        <Container>

          <Row>
            <Col xs={12} sm={12} md={11} lg={12} xl={9} className='mx-auto'>
              <h1>Affiliate FAQ’s</h1>

              <Row>
                <Col md={12}>
                  <Accordion defaultActiveKey="0">
                    {faqs?.map((faq, f) => (
                      <Accordion.Item eventKey={f} key={f}>
                        <Accordion.Header>
                          <div className="icon_faq">
                            <BsFillQuestionCircleFill />
                          </div>
                          {faq?.attributes?.Questions}
                        </Accordion.Header>
                        <Accordion.Body>{faq?.attributes?.Answer}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>

  )
}

export default AffiliateFAQ