import * as moment from 'moment'

export const GetFormattedCurrency = (num) => {
  if (!isNaN(num)) {
    num = parseFloat(num);
    return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "";
  }
};

export const GetFormattedCurrencyWithSuffixK = (num) => {
  if (!isNaN(num)) {
    num = parseFloat(num);
    num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    let formattedNumber;
    if (num >= 1000000) {
      formattedNumber = (num / 1000000).toFixed(2) + 'M';
    } else if (num >= 1000) {
      formattedNumber = (num / 1000).toFixed(2) + 'K';
    } else {
      formattedNumber = num.toFixed(2);
    }

    return `$${formattedNumber}`
  } else {
    return "";
  }
};

export const GetFormattedCurrency2 = (num) => {
  if (!isNaN(num)) {
    num = parseFloat(num);
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "";
  }
};

export const getFormattedCurrencyInInteger = (num) => {
  if (!isNaN(num)) {
    num = parseFloat(num);
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "";
  }
};

export const GetFormattedDate = (date1) => {
  if (!date1) return '';

  return moment(date1).format("MM/DD/YYYY");
}


export const GetFormattedNumber = (num) => {
  if (!isNaN(num)) {
    num = Number(num);
    return num.toLocaleString();
  } else {
    return "";
  }
};

export const formatCardEndFourDigits = (num) => {
  if (!isNaN(num)) {
    let formattedStr = String(num).padStart(4, '0');

    return formattedStr;
  }

  return '';
}

export const formatNumberWithCommas = (num) => {
  const numStr = num.toString();
  const parts = numStr.split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  const formattedNum = parts.length > 1 ? `${integerPart}.${parts[1]}` : integerPart;
  return formattedNum;
}

