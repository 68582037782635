import { BsFillLightningChargeFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { PACKAGES_GET_LIST_ENDPOINT } from "../../constants/api-routes.constant";
import { Row, Col, Container, Nav } from "react-bootstrap";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { getFormattedCurrencyInInteger } from "../../utils/formating.util";
import { STRAPI_PRICING_PLAN, StripeAxios, SEO_CONTENT_OF_PRICING_PAGE } from "../../constants/strapi-api-constant";
import Loader from "../../components/Loader";

import Seo from './../../components/Seo';

const Pricing = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const [type, setType] = useState('basic');
  const [packages, setPackages] = useState([]);
  const [selectData, setSelectData] = useState([]);
  // const [leftPkg, setLeftPkg] = useState({});
  // const [rightPkg, setRightPkg] = useState({});
  // const history = useNavigate();
  const [loading, setLoading] = useState(false)

  const [content, setContent] = useState({});
  const [clientRef, setClientRef] = useState('');
  const [metaData, setMetaData] = useState({});


  const getQueryParams = (qs) => {
    qs = qs.split('+').join(' ');

    var params = {},
      tokens,
      re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
  }

  // const dumyData = [
  //   "20k Property Records",
  //   "10k Skip Traces",
  //   "1M List Storage",
  //   "Dynamic Lead Filtering ",
  //   "Nationwide Real Estate Data",
  //   "Dedicated Support Agent"
  // ];


  const loadPackages = (data) => {
    const proPackages = data.filter(item => item.title.includes("Basic"));
    setSelectData(proPackages)
  }


  const GetPaymentsList = () => {
    setShowLoader(true);

    fetch(`${PACKAGES_GET_LIST_ENDPOINT}`, {
      method: "get",
    })
      .then((response) => response?.json())
      .then((response) => {
        if (response && response?.statusCode === 200) {
          setPackages(response?.result);
          loadPackages(response?.result);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setErrorMsg(error?.message);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    document.title = `Pricing Plans - ${process.env.REACT_APP_NAME}`;
    window.scrollTo(0, 0)
    GetPaymentsList()
    getStrapiContent()

    var query = getQueryParams(document.location.search);
    if (query.ref != 'undefined' && query.ref != null) {
      const cRef = '?ref=' + query.ref;
      setClientRef(cRef);
      localStorage.setItem('00ref', cRef);
    } else {
      const cRef = localStorage.getItem('00ref');
      if (cRef != null) {
        setClientRef(cRef);
      }
    }
  }, [])

  const proTypeHandler = (type) => {
    setType(type.toLowerCase());
    const proPackages = packages.filter(item => item.title.includes(type));
    setSelectData(proPackages)
  }


  const getStrapiContent = async () => {
    try {
      setLoading(true)
      const getPageContentApi = await StripeAxios.get(STRAPI_PRICING_PLAN);
      const getPageSeoContentApi = await StripeAxios.get(SEO_CONTENT_OF_PRICING_PAGE);

      const [getPageContentResp, getPageSeoContentResp] = await Promise.all([getPageContentApi, getPageSeoContentApi]);

      if (getPageContentResp && getPageContentResp?.data) {
        setLoading(false)
        const { data } = getPageContentResp?.data;
        setContent({ BannerTitle: data?.attributes?.BannerTitle, BannerDescription: data?.attributes?.BannerDescription });
      }

      if (getPageSeoContentResp && getPageSeoContentResp?.data) {
        const { data } = getPageSeoContentResp?.data;
        setMetaData(data?.attributes?.Seo || {});
      }

    } catch (error) {
      setLoading(false)
      console.log("Pricig page getting content error", error)
    }

  }




  return (
    <>
      {loading ? <Loader /> : ''}

      <Seo metaData={metaData} />

      <header className="header-inner">
        <h1>{content.BannerTitle}</h1>
        <p>{content.BannerDescription}</p>
      </header>

      <Container>
        <Row>
          <Col xs={11} sm={11} md={8} lg={8} className="mx-auto mt-5 mb-3 p-2">
            <Row>
              <Col md={12}>
                <div className="top_button_section">
                  <ul>
                    <li className={`${type === 'basic' ? 'active' : ''}`} onClick={() => proTypeHandler('Basic')}>Basic</li>
                    <li className={`${type === 'pro' ? 'active' : ''}`} onClick={() => proTypeHandler('Pro')}>Pro</li>
                    <li className={`${type === 'vip' ? 'active' : ''}`} onClick={() => proTypeHandler('VIP')}>VIP</li>
                  </ul>
                </div>
              </Col>
            </Row>

          </Col>
        </Row>


        <Row className="d-flex justify-content-center mb-5">

          {selectData?.map((pack, p) => {
            const features = pack?.features?.split('\n') || [];

            return (
              <Col xs={11} sm={12} md={6} lg={4} xl={3} key={p} className="mb-5">
                {/* <div className={`pricing_item ${p == 1 ? 'most' : ''}`}> */}
                <div className={`pricing_item `}>
                  {/* {p == 1 &&
                      <div className="most_overlay">
                        <BsFillLightningChargeFill />
                        <h4>Most Popular</h4>
                        <BsFillLightningChargeFill />
                      </div>
                    } */}

                  <div className="pricing_item_header">
                    <h4>{pack.title}</h4>
                  </div>
                  <div className={`pricing_item_price`}>
                    <div className="pricing">
                      <span className="dollar">$</span>
                      <span className="amount">{getFormattedCurrencyInInteger(pack.price)}</span>
                      <span className="type">/{type === 'year' ? 'year' : 'month'}</span>
                    </div>



                    {pack.isFreePackage ?

                      (
                        <button
                          className={'select_plan'}
                          onClick={() => window.location.href = `${process.env.REACT_APP_USER_SIDE_URL}/register${clientRef}`}
                        >
                          Create Account
                        </button>
                      )

                      :

                      (
                        <button
                          className={'select_plan'}
                          onClick={() => window.location.href = `${process.env.REACT_APP_USER_SIDE_URL}/register-payment${clientRef}`}
                        >
                          Create Account
                        </button>
                      )

                    }

                    {/*
                    <button
                      className={'select_plan'}
                      onClick={() => window.location.href = `${process.env.REACT_APP_USER_SIDE_URL}/register${clientRef}`}
                    >
                      Create Account
                    </button>
                    */}

                    <div className="details">
                      <h4>Plan Features:</h4>
                      <ul>
                        {features?.map((item, index) => {
                          return (
                            <li key={index}>{item}</li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>

                </div>
              </Col>
            );
          })}
        </Row>



      </Container>


    </>
  );
};

export default Pricing;
