export const MapBox_API_TOKEN = 'pk.eyJ1Ijoic2tpcGlmeSIsImEiOiJjbGRsdmF4OXMwM2UxM3dudzJmZnQzNmpkIn0.GsWX-1VgRIG0sCF8yzl_4w'

// export const Google_reCaptcha_Site_Key = '6Lef39kmAAAAACOaRJBkHswsLeMmE3qedwOhmttr';
// export const Google_reCaptcha_Secret_Key = '6Lef39kmAAAAAIkB1Jn_RgZY4ttXGsAravbRn9QB';

export const Google_reCaptcha_Site_Key = '6LcZNCMpAAAAANmyH8UXo28q9XFiXWzzY1gBUMGs';
export const Google_reCaptcha_Secret_Key = '6LcZNCMpAAAAAAmIeWR4sGYAWtI1yeQhuHYY7Qb4';

export const STRIPE_PK = 'pk_test_1QeXR8GOhQzU7aIzEz32d5kHxNETRMPgqAQ4EJCmj0KJUIk3T0cryjZXXvv3DANtB28kbFz99EYzqHAodDDW5RC1e00j8niG6dE';
export const STRIPE_SK = '379A6UnkrfQU9zz0K8csiRkFSOR8jG1h';

export const GOOGLE_MAP_KEY = 'AIzaSyANzEJpfir-hjdjnJ22aepwkHFJtvM6RkI';
