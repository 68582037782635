
const API_HOST = process.env.REACT_APP_API_HOST;

export const SET_TIME_OUT = 5000;

// Package list
export const PACKAGES_GET_LIST_ENDPOINT = `${API_HOST}/package/list`


// Contact us 
export const SUBMIT_CONTACTUS_FORM_ENDPOINT = `${API_HOST}/contact/add`;
export const CONTACT_SALES_ADD_ENDPOINT = `${API_HOST}/contact-sales/add`;
// personal indo
export const SUBMIT_PERSONAL_INFO_ADD = `${API_HOST}/home/personal-info/add`;
// Newsletter subscribe
export const SUBMIT_NEWSLETTER_SUBSCRIBE = `${API_HOST}/home/newsletter/subscribe`;


export const BOOK_DEMO_CREATE_ENDPOINT = `${API_HOST}/demo/add`;

export const AFFILIAT_APPLY_FORM = `${API_HOST}/affiliate/apply`;

