import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Slider from "react-slick";
import ReviewCard from './components/ReviewCard';
import { STRAPI_REVIEW, StripeAxios } from '../../../constants/strapi-api-constant';
const Review = () => {

  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getReviews()
  }, [])


  const getReviews = async () => {
    try {
      setLoading(true)
      await StripeAxios.get(STRAPI_REVIEW).then(res => {
        const { data } = res.data;
        setReviews(data?.reverse() || []);
        setLoading(false)
      }).catch(err => { setLoading(false); console.log('Reviews', err) });

    } catch (error) {
      setLoading(false)
      console.log("Review api error", error);
    }
  }
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='review'>
      <Row>
        <Col md={12} lg={10} xl={10} className='mx-auto text-center'>
          <h4>Here’s what our clients say</h4>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={{ span: 11, offset: 1 }} xl={{ span: 11, offset: 1 }}>
          <div className='slider_div'>
            <Slider {...settings}>
              {reviews?.map((item, r) => (
                <ReviewCard key={r} review={item?.attributes} />
              ))}

            </Slider>
          </div>
        </Col>
      </Row>


    </div>
  )
}

export default Review