import React from "react";
import { Helmet } from "react-helmet";

const HubspotTracking = () => (
  <Helmet>
    <script
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src="//js.hs-scripts.com/44343916.js"
    ></script>
  </Helmet>
);

export default HubspotTracking;
