import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { STRAPI_CAMPAIGN_FREE_DOWNLOAD, STRAPI_SUBMIT_FREE_DOWNLOAD_SUPPORT, StripeAxios, STRAPI_IMAGE, STRAPI_API_HOST } from '../../constants/strapi-api-constant'
import { Navbar, Container, Nav, Modal, Form, Image, Row, Col, Button, Spinner } from "react-bootstrap";
import { AiFillCheckSquare } from "react-icons/ai";
import Seo from '../../components/Seo'
import { DOWNLOAD_REQUEST_FORM } from '../../constants/api-routes.constant';
import logo from "../../assets/images/logo.png";
import { Link } from 'react-router-dom';
import { BsFillExclamationCircleFill } from "react-icons/bs";

import ReCAPTCHA from "react-google-recaptcha";
import {
  Google_reCaptcha_Site_Key,
} from "../../constants/api-credentials.constant";


const recaptchaRef = React.createRef();

const FreeDownloadForm = () => {
    
    const { slug } = useParams();
    const history = useNavigate();
    const [slugItemDetail, setSlugItemDetail] = useState({});
    const [contentDes, setContentDes] = useState();
    const [contentLi, setContentLi] = useState([]);
    const [isChecked, setIsChecked] = useState(true);
    
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        phoneNumberWithMasking: "",
        titleOfScript: "",
        optInToSMS: isChecked
    })

    const getSingleItemDetail = async () => {
        try {
          await StripeAxios.get(`${STRAPI_API_HOST}/skipify-free-download-items/${slug}?populate=*`).then(res => {
            const { data } = res.data;
            if (data) {
              
              setSlugItemDetail(data);
              state.titleOfScript=data.attributes?.ItemTitle;
              console.log(state);

              try {
                    setContentDes(data.attributes?.ItemDescription?.split('```')[0]);
                    let liDes = data.attributes?.ItemDescription?.split('```\n')[1];
                    setContentLi(liDes.split('\n'));

                } catch(err) {
                    console.log("Failed to load Item Description as it is null. More Reference => " + err)
                }
            } else {
              history('/404')
            }
          })
            .catch(err => {
              const { data } = err?.response;
              if (data.data == null) {
                history('/404')
              }
              console.log(err );
            });
    
        } catch (error) {
          setLoading(false)
          console.log("single api error", error);
        }
    }


    const [content, setContent] = useState({});

    useEffect(() => {
        async function fetchData() {
            try {
                await Promise.all([getPageContent(), getSingleItemDetail()]);
            } catch (error) {
                console.log("Error fetching data:", error);
            }
        }
        fetchData();
    }, [slug]) 

    const getPageContent = async () => {
        try {
            await StripeAxios.get(STRAPI_CAMPAIGN_FREE_DOWNLOAD).then(res => {
                const { data } = res.data;
                setContent({
                    MainTitle: data.attributes?.MainTitle,
                    PageDescription: data.attributes?.PageDescription,
                    CheckboxTitle: data.attributes?.CheckboxTitle,
                    SubmitLabel: data.attributes?.SubmitLabel,
                    FormDescription: data.attributes?.FormDescription,
                    LeftAreaImage: data.attributes?.LeftAreaImage ? data.attributes?.LeftAreaImage?.data?.attributes?.url : ""
                })
                
            }).catch(err => console.log('Error fetching Stripe content:', err));

        } catch (error) {
            console.log('Error fetching Stripe content:', error);
        }
    }
     
    const [metaData, setMetaData] = useState({});
    const [loading, setLoading] = useState(false)

    
    const [successMsg, setSuccessMsg] = useState(false);

    const [firstNameErr, setFirstNameErr] = useState('')
    const [lastNameErr, setLastNameErr] = useState('')
    const [emailErr, setEmailErr] = useState('')
    const [phoneNumberErr, setPhoneNumberErr] = useState('')

    const inputRefFirstName = useRef(null);
    const inputRefLastName = useRef(null);
    const inputRefEmail = useRef(null);
    const inputRefPhoneNumber = useRef(null);

    function handleChange(e) {
        const copy = { ...state }
        copy[e.target.name] = e.target.value
        setState(copy)
    }

    // Function to remove the Masking from phone Number
    const NormalizePhoneNumber = (phone) => {
        
        const copy = { ...state }

        const numericOnly = phone.replace(/\D/g, '');
        let maskedNumber = '';
        if (numericOnly.length > 0) {
        maskedNumber = `(${numericOnly.slice(0, 3)}`;
        }
        if (numericOnly.length > 3) {
        maskedNumber += `) ${numericOnly.slice(3, 6)}`;
        }
        if (numericOnly.length > 6) {
        maskedNumber += `-${numericOnly.slice(6, 10)}`;
        }

        
        copy['phoneNumber'] = phone;
        copy['phoneNumberWithMasking'] = maskedNumber;
        setState(copy)

        console.log(state);

    }

    const downloadPdf = async () => {
        try {
          const response = await StripeAxios.get(
                slugItemDetail.attributes?.DownloadURL,
            {
              responseType: "blob", 
            }
          );
          const pdfBlob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(pdfBlob);
          const tempLink = document.createElement("a");
          tempLink.href = url;
          tempLink.setAttribute(
            "download",
            `${slugItemDetail.attributes?.ItemTitle}.pdf`
          );
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(url);
            } catch (error) {
            console.error("Error downloading PDF:", error);
        }
    }

    function showSuccessMsg(){
        setSuccessMsg(true);
        setTimeout(()=>{
            setSuccessMsg(false);
        },5000);
    }

    async function handleSubmit(event) {
        
        event.preventDefault()

        setLoading(true)
        let flag = true;

        console.log(state);
        
        if (state?.firstName.trim() == '') {
            setFirstNameErr('First Name is required')
            inputRefFirstName.current.focus();
            flag = false;
            setLoading(false)
        }

        if (state?.lastName.trim() == '') {
            setLastNameErr('Last Name is required')
            inputRefLastName.current.focus();
            flag = false;
            setLoading(false)
        }

        if (state?.email == '') {
            setEmailErr('Email Address is required')
            inputRefEmail.current.focus();
            flag = false;
            setLoading(false)
        }


        if (state?.phoneNumber == '') {
            setPhoneNumberErr('Phone Number is required')
            inputRefPhoneNumber.current.focus();
            flag = false;
            setLoading(false)
        }

        if (flag) {
            
            const res = await fetch(`${process.env.REACT_APP_API_HOST}/contact/free-download`, {
                method: "POST",
                body: JSON.stringify(state),
                headers: {
                    "Content-Type": "application/json"
                }
            })

            const json = await res.json()

            if (res.ok) {
                
                showSuccessMsg();
                
                const handleCmsContentPromise = handleCmsContent();
                const [handleCmsContentResponse] = await Promise.all([
                    handleCmsContentPromise,
                ]);
                
                setLoading(false)
                
                setState({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    phoneNumberWithMasking: "",
                    optInToSMS: isChecked
                })

                await downloadPdf();

            } else {
                setLoading(false)

                let errors = ""
                if (Array.isArray(json.message)) {
                    for (let i = 0; i < json.message.length; i++) {
                        errors += json.message[i] + "<br>"
                    }
                } else {
                    errors = json.message
                }
            }
        }
    }

    //submit content to CMS
    const handleCmsContent = () => {
        const cmsdata = {
            "data": {
                "FirstName": state?.firstName,
                "LastName": state?.lastName,
                "Email": state?.email,
                "PhoneNumber": state?.phoneNumber,
                "titleOfScript": state.titleOfScript,
                "optInToSMS": state.optInToSMS
            }
        }
        StripeAxios.post(STRAPI_SUBMIT_FREE_DOWNLOAD_SUPPORT, cmsdata)
        .then(res => {
            setLoading(false)
            return res;
        })
        .catch(err => { setLoading(false); console.log("Free Download Support Submit Message => ", err) })
    }

    
    // SMS Sent Checkbox
    const handleSMS = () => {
        setIsChecked(!isChecked);
        state.optInToSMS=!isChecked;
    };
 
    return (
        <div>
            <Seo metaData={metaData} />
            <div className='contact-page free-download-form'>
                <Row>
                    <Col sm={12} md={5} className='mx-auto p-4 mob-order-2'>
                        <Row>
                            <Col md={12} className='left-area'>
                                <Image src={STRAPI_IMAGE + slugItemDetail.attributes?.ItemImage.data.attributes.url}></Image>
                                <p className='mt-4'>
                                    {contentDes}
                                </p>
                                
                                <ul>
                                    {contentLi.map((item,i) =>
                                        <li key={i}><div className='icon'><AiFillCheckSquare /></div>{item}</li>
                                    )}
                                </ul>
                                
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} className='mx-auto right-area mob-order-1'>
                        <Row className='px-2'>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mx-auto my-3 top-right">
                            
                                <Image md={4} src={logo}></Image>
                                <h3 className="text-start">{content?.MainTitle}<br></br>
                                {slugItemDetail.attributes?.ItemTitle} </h3>
                                <p>{content?.PageDescription}</p>
                                {
                                    successMsg ? <>
                                        <div class="alert alert-success cst-success" role="alert">
                                            Thank you for submitting your information. Your download will be available shortly.
                                        </div>
                                    </> : <></>
                                }
                            </Col>
                            <form className='px-4' onSubmit={handleSubmit} > 
                                
                                <Row className='mb-4'>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <label>First Name <span>*</span></label>
                                        <Form.Control
                                            name="firstName"
                                            ref={inputRefFirstName}
                                            value={state.firstName}
                                            onChange={(e) => { handleChange(e); setFirstNameErr(''); }}
                                            type="text"
                                            placeholder="John"
                                            id="first_name"
                                        /> 
                                        {firstNameErr ? <span className="text-danger"><BsFillExclamationCircleFill /> {firstNameErr}</span> : ""}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <label>Last Name <span>*</span></label>
                                        <Form.Control
                                            name="lastName"
                                            ref={inputRefLastName}
                                            value={state.lastName}
                                            onChange={(e) => { handleChange(e); setLastNameErr('') }}
                                            type="text"
                                            placeholder="Doe"
                                            id="last_name"
                                        /> 
                                        {lastNameErr ? <span className="text-danger"><BsFillExclamationCircleFill /> {lastNameErr}</span> : ""}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <label>Email <span>*</span></label>
                                        <Form.Control
                                            name="email"
                                            ref={inputRefEmail}
                                            value={state.email}
                                            onChange={(e) => { handleChange(e); setEmailErr('') }}
                                            type="email"
                                            placeholder="johndoe@email.com*"
                                            id="email"
                                        /> 
                                        {emailErr ? <span className="text-danger"><BsFillExclamationCircleFill /> {emailErr}</span> : ""}
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <label>Phone Number  <span>*</span></label>
                                        <Form.Control
                                            type="text"
                                            ref={inputRefPhoneNumber}
                                            name="phoneNumber"
                                            id="phone_number"
                                            placeholder="Phone # (123) 234-3453"
                                            onChange={(e) => { NormalizePhoneNumber(e.target.value.replace(/\D/g, '')); setPhoneNumberErr("") }}
                                            value={state?.phoneNumberWithMasking}
                                        />
                                        {phoneNumberErr ? <span className="text-danger"><BsFillExclamationCircleFill /> {phoneNumberErr}</span> : ""}
                                    </Col>
                                </Row>

                                {/* recaptcha */}
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={Google_reCaptcha_Site_Key}
                                />
                                
                                <Row className='justify-content-center mt-4'>
                                    <Col md={12} className='text-center'>
                                        <div className="alert alert-success hide" role="alert">
                                            Sent
                                        </div>
                                    </Col> 
                                    <Col md={12}>
                                        <div className='checkbox-label'>
                                            <label className="cst-checkbox">{content?.CheckboxTitle}
                                                <input 
                                                  type="checkbox" 
                                                  checked={isChecked}
                                                  onChange={()=>{handleSMS()}}
                                                ></input>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </Col>
                                    <Col md={7} sm={12}>
                                        <button type="submit" className="submit">
                                            {loading ? <Spinner animation="border" size="sm" /> : content?.SubmitLabel}
                                        </button>
                                        
                                    </Col> 
                                    
                                    
                                    <Col md={12} className='mt-4'>
                                        <p>
                                            {content?.FormDescription}
                                        </p>
                                    </Col>
                                    <Col md={7} className='mt-4'>
                                        <Link className="privacy-policy-btn" to="/terms">Terms of Service</Link>
                                        <Link className="privacy-policy-btn mt-15" to="/privacy-policy">Privacy Policy</Link>
                                    </Col>
                                </Row>
                            </form>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default FreeDownloadForm