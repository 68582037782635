import { useEffect, useState } from 'react';
import { Col, Row, Image } from 'react-bootstrap'
import { STRAPI_IMAGE, STRAPI_PARTNER, StripeAxios } from '../../../constants/strapi-api-constant';
import Loader from '../../../components/Loader';

import Signature from './../../../assets/images/signature.png';

import placeholder from './../../../assets/images/placeholder_v.png';
import { LazyLoadImage } from "react-lazy-load-image-component";

const HomeMainBottomSection = () => {

    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getPartnerData()
    }, [])


    const getPartnerData = async () => {
        try {
            setLoading(true)
            await StripeAxios.get(STRAPI_PARTNER).then(res => {
                const { data } = res.data;
                setContent(data?.attributes)
                setLoading(false)
            })
                .catch(err => { setLoading(false); console.log('Partners section', err) });

        } catch (error) {
            setLoading(false)
            console.log("Partner api error", error);
        }
    }

    return (
        <section className="main-bottom-section">

            <Row>
                <Col md={10} className="mx-auto">
                    <Row>
                        <Col md={10} className="mx-auto headtext">
                            <h4>{content?.Title}</h4>
                            <p>{content?.Description}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <div className="message_text_image">
                                {content.OtherImage ?
                                    <Image src={STRAPI_IMAGE + content.OtherImage?.data?.attributes?.url} className='img-fluid' alt="real estate matt logo" />
                                    :
                                    <Image src={Signature} className='img-fluid' alt="real estate matt logo" />
                                }

                                <p>{content?.AboutPartner ? content?.AboutPartner : ""} </p>
                                <p>{content?.Name ? content?.Name : ""} </p>
                                <h6>{content?.Designation ? content?.Designation : ""} </h6>
                            </div>
                        </Col>
                        <Col md={3} className='d-flex justify-content-center align-items-center'>
                            {content.Image ?
                                <LazyLoadImage
                                    src={STRAPI_IMAGE + content.Image?.data?.attributes?.url}
                                    PlaceholderSrc={STRAPI_IMAGE + content.Image?.data?.attributes?.url}
                                    effect="blur"
                                    className='img-fluid'
                                    alt="real estate matt logo"
                                />
                                :
                                <LazyLoadImage src={placeholder}
                                    // width={600} height={400}
                                    alt="real estate matt logo"
                                    PlaceholderSrc={placeholder}
                                    effect="blur"
                                />
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>

        </section>
    )
}

export default HomeMainBottomSection;