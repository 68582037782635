import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Placeholder from './../../../assets/images/placeholder.png';
import { Link } from 'react-router-dom';
import { STRAPI_IMAGE, STRAPI_WHY_SKIPIFY, StripeAxios } from '../../../constants/strapi-api-constant';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";

const WhyChoseUs = () => {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getWhySkipify();
    }, []);


    const getWhySkipify = async () => {
        try {
            setLoading(true)
            await StripeAxios.get(STRAPI_WHY_SKIPIFY).then(res => {
                const { data } = res.data;
                setContent(data?.attributes)
                setData(data?.attributes?.home_services?.data)
                setLoading(false)
            })
                .catch(err => { setLoading(false); console.log('Why Skipify', err) });

        } catch (error) {
            setLoading(false)
            console.log("Why Skipify api error", error);
        }
    }

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='main-top-section'>
            <Row>
                <Col md={8} className='mx-auto whychoseus'>

                    <h4>{content?.Title ? content?.Title : ''}</h4>
                    <p >{content?.Description ? content?.Description : ''}</p>
                </Col>
            </Row>

            <Row>
                <Col md={10} className="mx-auto my-3 chose_sec">
                    <Row>
                        <div className='slider_div'>
                            <Slider {...settings}>
                                {data?.map((item, i) => (
                                    <Col xs={12} sm={6} md={6} lg={3} key={i}>
                                        <div className='chose_item'>
                                            {item?.attributes?.Image ?
                                                <LazyLoadImage src={STRAPI_IMAGE + item?.attributes?.Image?.data?.attributes?.url}
                                                    PlaceholderSrc={STRAPI_IMAGE + item?.attributes?.Image?.data?.attributes?.url}
                                                    effect="blur"
                                                    className='img-fluid'
                                                    alt="icons"
                                                />
                                                :
                                                <LazyLoadImage src={Placeholder}
                                                    PlaceholderSrc={Placeholder}
                                                    alt="icons"
                                                    className='img-fluid'
                                                />
                                            }
                                            <h4>{item?.attributes?.Title}</h4>
                                        </div>
                                    </Col>
                                ))}

                            </Slider>
                        </div>

                    </Row>
                    <Row>
                        <Col xs={8} sm={10} md={4} lg={2} className='mx-auto mt-5 mb-2 '>
                            <Link to="/about-us" className=' btn btn-primary main'>About Us</Link>
                        </Col>
                    </Row>


                </Col>
            </Row>
        </div>
    )
}

export default WhyChoseUs