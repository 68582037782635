import icon01 from '../../../assets/images/icon/1.png'
import { useState, useEffect } from 'react'
import { STRAPI_IMAGE, STRAPI_OUR_SERVICES, StripeAxios } from '../../../constants/strapi-api-constant';
// import Loader from '../../../components/Loader';

import { Row, Col, Image } from 'react-bootstrap'

const HomeMiddleSection = () => {

    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getOurServices()
    }, [])


    const getOurServices = async () => {
        try {
            setLoading(true)
            await StripeAxios.get(STRAPI_OUR_SERVICES).then(res => {
                const { data } = res.data;
                setContent(data?.attributes)
                setData(data?.attributes?.home_our_services?.data)
                setLoading(false)
            }).catch(err => { setLoading(false); console.log('Our Services', err) });

        } catch (error) {
            setLoading(false)
            console.log("Our Servies api error", error);
        }
    }


    return (
        <section className="main-middle-section">
            <Row>
                <Col xs={12} sm={10} md={10} lg={6} className='mx-auto'>
                    <h3>{content?.Title ? content?.Title : ''}</h3>
                    <h4>{content?.Description ? content?.Description : ''}</h4>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={8} className="mx-auto mt-5 p-4">
                    <Row>
                        {data?.map((card, k) => (
                            <Col xs={12} sm={6} md={6} lg={4} key={k}>
                                <div className='service_item'>
                                    {card?.attributes?.Image ?
                                        <Image src={STRAPI_IMAGE + card?.attributes?.Image?.data?.attributes?.url} className='img-fluid' alt='icons' />
                                        :
                                        <Image src={icon01} className='img-fluid' alt='icons' />
                                    }
                                    <h4>{card?.attributes?.Title ? card?.attributes?.Title : ''}</h4>
                                    <h6>{card?.attributes?.Description ? card?.attributes?.Description : ''}</h6>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col xs={10} sm={8} md={4} lg={3} className='mx-auto mt-5 mb-3 '>
                    <a href="/pricing-plan" className='main btn btn-primary'>Create Free Account</a>
                    {/* <Link to='/register' className='main btn btn-primary'>Create Free Account</Link> */}
                </Col>
            </Row>
        </section>
    )
}
export default HomeMiddleSection;