import React, { useEffect, useState } from 'react'
import { STRAPI_IMAGE, STRAPI_SKIPIFY_TV, STRAPI_SKIPIFY_U_CONTENT, STRAPI_FREE_DOWNLOAD_ITEMS, StripeAxios } from '../../constants/strapi-api-constant'
import { Col, Row } from 'react-bootstrap'
import VedioItem from '../../components/VedioItem'
import FreeDownloadItems from '../../components/FreeDownloadItems'
import HBG from './../../assets/images/hbg.jpg'
import Seo from './../../components/Seo'
const SkipifyTv = () => {
    const [vedios, setVedios] = useState([]);
    const [downloadItems, setDownloadItems] = useState([]);
    const [downloadItemContent, setDownloadItemContent] = useState({});
    const [content, setContent] = useState({});
    const [metaData, setMetaData] = useState({});

    useEffect(() => {
        async function fetchData() {
            try {
                await Promise.all([getPageVedioContent(), getPageContent(), getPageFreeDownloadItemContent()]);
            } catch (error) {
                console.log("Error fetching data:", error);
            }
        }
        fetchData();
    }, [])

    const getPageVedioContent = async () => {
        try {
            await StripeAxios.get(STRAPI_SKIPIFY_TV).then(res => {
                const { data } = res.data;
                setVedios(data)
            }).catch(err => console.log('Skipify u', err));

        } catch (error) {
            console.log('Error fetching video content:', error);
        }
    }

    const getPageFreeDownloadItemContent = async () => {
        try {
            await StripeAxios.get(STRAPI_FREE_DOWNLOAD_ITEMS).then(res => {
                const { data } = res.data;
                setDownloadItems(data)
            }).catch(err => console.log('Skipify u', err));

        } catch (error) {
            console.log('Error fetching video content:', error);
        }
    }

    const getPageContent = async () => {
        try {
            await StripeAxios.get(STRAPI_SKIPIFY_U_CONTENT).then(res => {
                const { data } = res.data;
                setMetaData(data.attributes?.Seo)
                setContent({
                    BannerTitle: data.attributes?.BannerTitle,
                    BannerDescription: data.attributes?.BannerDescription,
                    BannerMegaTitle: data.attributes?.BannerMegaTitle,
                    DownloadHeading: data.attributes?.downloadHeading,
                    image: data.attributes?.Image ? data.attributes?.Image?.data?.attributes?.url : ""
                })
                setDownloadItemContent({
                    DownloadURL: data.attributes?.DownloadURL,
                    ItemTitle: data.attributes?.ItemTitle,
                    Label: data.attributes?.Label,
                    // ItemImage: data.attributes?.DownloadURL,
                })
            }).catch(err => console.log('Error fetching video content:', err));

        } catch (error) {
            console.log('Error fetching video content:', error);
        }
    }
    return (
        <div>
            <Seo metaData={metaData} />
            <header className="header-inner2" style={{ background: `url(${STRAPI_IMAGE + content?.image}`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                <Row>
                    <Col xs={11} sm={11} md={12} lg={12} xl={12} className="mx-auto">
                        <h3 className="text-center">{content?.BannerTitle}</h3>
                        <h6 className="text-center">{content?.BannerMegaTitle}</h6>
                        <p className="text-center">{content?.BannerDescription}</p>
                    </Col>
                </Row>
            </header>

            <div className='blogs_section'>
                <Row>
                    <Col sm={12} md={10} xl={10} className='mx-auto'>


                        <Row>
                            <Col xs={11} sm={11} md={12} lg={12} xl={12} className="mx-auto my-3">
                                <h3 className="text-start">{content?.DownloadHeading}</h3>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-start video-blogs mb-4'>
                            {downloadItems?.map((vedio, v) => (
                                <Col xs={12} sm={6} md={6} xl={4} key={v} className='mb-3'>
                                    <FreeDownloadItems downloadItem={vedio} />
                                </Col>
                            ))}
                        </Row>


                        <Row>
                            <Col xs={11} sm={11} md={12} lg={12} xl={12} className="mx-auto my-3">
                                <h3 className="text-start">Browse Our Videos</h3>
                            </Col>
                        </Row>

                        <Row className='d-flex justify-content-start video-blogs'>
                            {vedios?.map((vedio, v) => (
                                <Col xs={12} sm={6} md={6} xl={4} key={v} className='mb-3'>
                                    <VedioItem vedio={vedio} />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SkipifyTv