import { AiFillPlayCircle } from "react-icons/ai";
import React from 'react'
import { STRAPI_IMAGE } from '../constants/strapi-api-constant'
import { Card, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const VedioItem = ({ vedio }) => {
  const history = useNavigate()
  const handleRedirect = () => {
    history('/skipify-detail', { state: { vedio } })
  }
  return (
    <Card className='blog-card play' onClick={() => handleRedirect()}>
      <div className="ovarlay"></div>
      <Card.Img variant="top" src={STRAPI_IMAGE + vedio?.attributes?.Thumbnail?.data?.attributes?.url} />
      <Card.Body className="d-flex justify-content-between align-items-center">
        <h5>{vedio.attributes.Title}</h5>
        {/* <AiFillPlayCircle color="#EFFAFE" size={50} cursor="pointer" onClick={() => handleRedirect()} /> */}
      </Card.Body>
    </Card>
  )
}

export default VedioItem