import { BrowserRouter } from 'react-router-dom';

import './assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'video-react/dist/video-react.css';  // import css
// slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AllRoutes from './routes';
import MaintenanceRoute from './routes/MaintenanceRoute';
import { useState } from 'react';

function App() {
  const maintenance = process.env.REACT_APP_IN_MAINTENANCE
  console.log(maintenance)
  return (
    <BrowserRouter >
      {maintenance == 'true' ? <MaintenanceRoute /> : <AllRoutes />}
    </BrowserRouter>
  );
}

export default App;
