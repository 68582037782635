import React, { useEffect, useState } from "react";
import { SET_TIME_OUT, SUBMIT_PERSONAL_INFO_ADD } from "../../constants/api-routes.constant";
import { Spinner, Form, Alert, Row, Col } from "react-bootstrap";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import { Google_reCaptcha_Site_Key } from "../../constants/api-credentials.constant";
import { STRAPI_PERSONAL_INFO, STRAPI_SUBMIT_PERSONAL_INFO, StripeAxios, SEO_CONTENT_OF_PERSONAL_INFO } from '../../constants/strapi-api-constant'
import Loader from "../../components/Loader";
import HubspotTracking from "../../components/HubspotTracking";
import Seo from "../../components/Seo";

const recaptchaRef = React.createRef();

const PersonalInfo = () => {
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        emailAddress: "",
        message: "",
    });
    const [fName, setfName] = useState("");
    const [lName, setlName] = useState("");
    const [emailAddress, setemailAddress] = useState("");
    const [message, setmessage] = useState("");
    const [load, setLoad] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [queryErr, setqueryErr] = useState("");
    const [content, setContent] = useState({})
    const [loading, setLoading] = useState(false)
    const [metaData, setMetaData] = useState({})


    useEffect(() => {
        document.title = `Personal Information - ${process.env.REACT_APP_NAME}`;
        window.scrollTo(0, 0);
        getPageContent()
    }, []);

    const EmailRegex = /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    // submit the contact form
    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoad(true);
        const token = await recaptchaRef.current.executeAsync();
        if (!token) {
            setqueryErr(`Error: Invalid captcha attempt.`);
            setLoad(false);
            return;
        } else {
            recaptchaRef.current.reset();
        }

        let errorFlag = false;
        if (!values?.firstName) {
            setfName("Please Enter First Name");
            setLoad(false);
            errorFlag = true;
        } else {
            setfName("");
        }

        if (!values?.lastName) {
            setlName("Please Enter Last Name");
            setLoad(false);
            errorFlag = true;
        } else {
            setlName("");
        }

        if (!values?.emailAddress) {
            setemailAddress("Please Enter Email Address");
            setLoad(false);
            errorFlag = true;
        } else if (!EmailRegex.test(values?.emailAddress)) {
            setemailAddress("Enter a valid email address");
            setLoad(false);
            errorFlag = true;
        } else {
            setemailAddress("");
        }

        if (!values?.message) {
            setmessage("Please Enter Message");
            setLoad(false);
            errorFlag = true;
        } else {
            setmessage("");
        }

        if (!errorFlag) {

            const submitPersonalInfoPromise = fetch(SUBMIT_PERSONAL_INFO_ADD, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    email: values?.emailAddress,
                    message: values?.message,
                }),
            });

            const handleCmsContentPromise = handleCmsContent();

            const [submitPersonalInfoResponse, handleCmsContentResponse] = await Promise.all([
                submitPersonalInfoPromise,
                handleCmsContentPromise,
            ]);


            if (submitPersonalInfoResponse.status == 200) {
                const submitPersonalInfoJson = await submitPersonalInfoResponse.json();
                setLoad(false);
                setSuccessMessage(submitPersonalInfoJson?.result);

                setTimeout(() => {
                    setSuccessMessage("");
                }, SET_TIME_OUT);

                setValues({
                    firstName: "",
                    lastName: "",
                    emailAddress: "",
                    message: "",
                });
            }
        }
    };

    //submit content to CMS
    const handleCmsContent = async () => {
        const cmsdata = {
            data: {
                FirstName: values?.firstName,
                Email: values?.emailAddress,
                LastName: values?.lastName,
                Message: values?.message,
            }
        }
        await StripeAxios.post(STRAPI_SUBMIT_PERSONAL_INFO, cmsdata)
            .then(res => {
                setLoading(false)
                return res;
            })
            .catch(err => { setLoading(false); console.log("contact support submit message", err) })
    }

    // On change handle inputs values
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    // Function to remove the Masking from phone Number
    const NormalizePhoneNumber = (phone) => {
        const numericOnly = phone.replace(/\D/g, "");
        let maskedNumber = "";
        if (numericOnly.length > 0) {
            maskedNumber = `(${numericOnly.slice(0, 3)}`;
        }
        if (numericOnly.length > 3) {
            maskedNumber += `) ${numericOnly.slice(3, 6)}`;
        }
        if (numericOnly.length > 6) {
            maskedNumber += `-${numericOnly.slice(6, 10)}`;
        }
        setValues({
            ...values,
            phoneNumber: numericOnly.slice(0, 10),
            phoneNumberWithMaking: maskedNumber,
        });
    };



    const getPageContent = async () => {
        setLoading(true)

        try {

            const getPersonalInfoContent = await StripeAxios.get(STRAPI_PERSONAL_INFO);
            const getPersonalInfoSeoContent = await StripeAxios.get(SEO_CONTENT_OF_PERSONAL_INFO);

            const [getPersonalInfoSeoContentResp, getPersonalInfoContentResp] = await Promise.all([
                getPersonalInfoSeoContent,
                getPersonalInfoContent
            ]);
            setLoading(false)

            if (getPersonalInfoContentResp && getPersonalInfoContentResp?.data) {
                const { data } = getPersonalInfoContentResp?.data;
                setContent({
                    heading: data.attributes?.BannerHead,
                    BannerDescription: data.attributes?.BannerDescription,
                    description: data.attributes?.PageDescription
                })
            }

            if (getPersonalInfoSeoContentResp && getPersonalInfoSeoContentResp?.data) {

                const { data } = getPersonalInfoSeoContentResp?.data;
                setMetaData(data?.attributes?.Seo || {});
            }
        } catch (error) {
            setLoading(false);
            console.log("contact sale Strapi Call for content", error)
        }
    }



    return (
        <>
            <HubspotTracking />
            <Seo metaData={metaData} />
            {loading && <Loader />}
            <header className="header-inner">
                <h1>{content.heading}</h1>
                <p>{content.BannerDescription}</p>
            </header>
            <section className="contact-page">
                <div className="container">
                    <Row className="d-flex justify-content-between">
                        <Col md={12} lg={5} className="mb-3">
                            <div className="contact_text">

                                {content?.description?.map((text, t) => {
                                    if (text.type == 'paragraph') {
                                        return (<p key={t} className={`${t === 0 ? 'mt-5' : ''}`}>{text.children[0].text}</p>)
                                    } else {
                                        return <ul>
                                            {text?.children?.map((li, i) => (
                                                <li key={i}>{li.children[0].text}</li>
                                            ))}
                                        </ul>
                                    }
                                })}

                            </div>
                        </Col>
                        <Col md={12} lg={6} className="px-5 mx-auto">
                            <div className="left-contact2">

                                <Row>
                                    <Col md={12}>
                                        {successMessage != "" ? <Alert variant="success">{successMessage} </Alert> : ""}
                                        {queryErr != "" ? <Alert variant="danger">{queryErr} </Alert> : ""}
                                    </Col>
                                </Row>
                                <form onSubmit={handleSubmit} method="POST" id="skipify_personal_information_form">
                                    <p className="require_span">Fields marked with an * are required</p>
                                    <Row>
                                        <Col md={6}>
                                            <input
                                                type="text"
                                                name="firstName"
                                                id="first_name"
                                                placeholder="First Name*"
                                                value={values?.firstName}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setfName("");
                                                }}
                                            />
                                            {fName &&
                                                <span className="text-danger">
                                                    <BsFillExclamationCircleFill />
                                                    {fName}
                                                </span>
                                            }
                                        </Col>
                                        <Col md={6}>
                                            <input
                                                type="text"
                                                name="lastName"
                                                id="last_name"
                                                placeholder="Last Name*"
                                                value={values?.lastName}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setlName("");
                                                }}
                                            />
                                            {lName &&
                                                <span className="text-danger">
                                                    <BsFillExclamationCircleFill /> {lName}
                                                </span>
                                            }
                                        </Col>
                                        <Col md={12}>
                                            <input
                                                type="email"
                                                name="emailAddress"
                                                id="email_address"
                                                placeholder="Email Address*"
                                                value={values?.emailAddress}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setemailAddress("");
                                                }}
                                            />
                                            {emailAddress ? (
                                                <span className="text-danger">
                                                    <BsFillExclamationCircleFill /> {emailAddress}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </Col>

                                        <Col md={12}>
                                            <Form.Group
                                                className="mb-3"
                                                controlId="exampleForm.ControlTextarea1"
                                            >
                                                <Form.Control
                                                    as="textarea"
                                                    rows={8}
                                                    value={values?.message}
                                                    id="message"
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setmessage("");
                                                    }}
                                                    name="message"
                                                    placeholder="Message*"
                                                />
                                            </Form.Group>
                                            {message ? (
                                                <span className="text-danger">
                                                    <BsFillExclamationCircleFill /> {message}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                    </Row>

                                    <button type="submit" className="submit">
                                        {load ? (
                                            <Spinner animation="border" size="sm" />
                                        ) : (
                                            "Send Message"
                                        )}
                                    </button>
                                </form>

                                {/* recaptcha */}
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={Google_reCaptcha_Site_Key}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

        </>
    );
};

export default PersonalInfo;
