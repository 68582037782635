import React, { useEffect } from 'react'
import { Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFound = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <header className="header-inner">
                <h1>404</h1>
            </header>
            <div className='page_not_found'>
                <Row>
                    <Col md={12} className='text-center'>
                        <Image src={require('./../../assets/images/404.png')} alt="404" />
                        <h1>OOPS!</h1>
                        <p>This Page Can’t Be Found.</p>
                        <Link to="/">Go To Home</Link>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default NotFound