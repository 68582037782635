import { BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from 'react'
import { Col, Row, } from 'react-bootstrap'
import { STRAPI_BLOGS, StripeAxios } from '../../../constants/strapi-api-constant'
import { Link } from 'react-router-dom'
import BlogItem from "./components/BlogItem";

const Blogs = () => {
    const [content, setContent] = useState([]);
    useEffect(() => {
        getPageContent()
    }, [])


    const getPageContent = async () => {
        try {

            await StripeAxios.get(STRAPI_BLOGS).then(res => {
                const { data } = res.data;
                setContent(data?.reverse())
            })
                .catch(err => console.log('blogs', err));

        } catch (error) {
            console.log("bogs api error", error);
        }
    }


    return (
        <div className='blogs_section'>
            <Row>
                <Col md={10} className='mx-auto'>

                    <Row className='d-flex justify-content-between my-5'>
                        <Col xs={12} sm={5} md={6} lg={4}>
                            <h4 className='title_blogs'>Latest Blogs</h4>
                        </Col>
                        <Col xs={12} sm={5} md={6} lg={2}>
                            <Link to="/blogs" className='main'>View All <BsArrowRight /></Link>
                        </Col>
                    </Row>
                    <Row>
                        {content?.map((blog, b) => {
                            if (b <= 2) {
                                return (
                                    <Col xs={12} sm={6} md={6} lg={4} xl={4} key={b} className='mb-3'>
                                        <BlogItem blog={blog} />
                                    </Col>
                                );
                            }
                        })}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Blogs