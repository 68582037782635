import { FaTiktok } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { Col, Row, Image, Spinner, Modal } from "react-bootstrap";
import logoFooter from "../assets/images/logo-footer.png";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import Xicon from "../assets/images/x-twitter.svg";
import { useState } from "react";
import { SUBMIT_NEWSLETTER_SUBSCRIBE } from "../constants/api-routes.constant";
import { AiFillCloseCircle } from "react-icons/ai";

const Footer = () => {
  const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const { pathname } = useLocation();

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [load, setLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [queryErr, setqueryErr] = useState("");

  // Modal
  const [show, setShow] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setqueryErr("");
    setLoad(true);
    let flag = false;
    if (!email) {
      setEmailErr("Please Enter Email Address");
      setLoad(false);
      flag = true;
    } else if (!EmailRegex.test(email)) {
      setEmailErr("Enter a valid email address");
      setLoad(false);
      flag = true;
    } else {
      setEmailErr("");
    }

    if (!flag) {
      fetch(SUBMIT_NEWSLETTER_SUBSCRIBE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          setqueryErr("Error: Invalid Username or Password provided.");
        })
        .then((response) => {
          if (response && response?.statusCode === 200) {
            setLoad(false);
            setSuccessMessage("Subscribed Successfully");
            setShow(true);
            setTimeout(() => {
              setSuccessMessage("");
              setShow(false);
            }, 5000);
            setEmail("");
          }
        })
        .catch((e) => {
          setLoad(false);
          setqueryErr("Error: Invalid Username or Password provided.");
        });
    }
  };

  const handleClose = () => setShow(false);

  const ShowModalMessage = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="glassModel"
      >
        <Modal.Body>
          <Row className="d-flex justify-content-end mb-2">
            <Col md={1}>
              <AiFillCloseCircle onClick={handleClose} cursor={"pointer"} />
            </Col>
          </Row>
          <div className="newsletter_message_modal">
            <h3>Thank You</h3>
            <h1>For subscribing to the Skipify.ai Newsletter!</h1>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const handleScreenTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <footer className="footer">
      <div className="footer_div container">
        {ShowModalMessage()}
        <Row className="mt-5">
          <Col xs={12} sm={12} md={12} lg={9}>
            <Row>
              <Col xs={12} sm={12} md={3} lg={3}>
                <div className="socialLinks">
                  <h4>Follow Us</h4>
                  <ul>
                    <li>
                      <a
                        title="Skipify.ai Facebook"
                        target="_blank"
                        href="https://www.facebook.com/skipifyai"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        title="Skipify.ai Instagram"
                        target="_blank"
                        href="https://www.instagram.com/skipify.ai/?igshid=MzRlODBiNWFlZA%3D%3D"
                      >
                        <BsInstagram />
                      </a>
                    </li>
                    <li>
                      <a
                        title="Skipify.ai LinkedIn"
                        target="_blank"
                        href="https://www.linkedin.com/company/skipify-ai/"
                      >
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        title="Skipify.ai YouTube"
                        target="_blank"
                        href="https://www.youtube.com/channel/UCFMuLK0m8gHQhm76ro4xVgg"
                      >
                        <AiFillYoutube />
                      </a>
                    </li>
                    <li>
                      <a
                        title="Skipify.ai Twitter"
                        target="_blank"
                        href="https://twitter.com/Skipify_ai"
                      >
                        <Image src={Xicon} width="15" alt="twitter logo" />
                      </a>
                    </li>
                    <li>
                      <a
                        title="Skipify.ai Tiktok"
                        target="_blank"
                        href="https://www.tiktok.com/@skipify.ai"
                      >
                        <FaTiktok />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} sm={12} md={3} lg={3}>
                <div className="footerColumn link">
                  <h4>Company </h4>
                  <ul>
                    <li>
                      {pathname == '/about-us' ? <a onClick={() => handleScreenTop()}>About Us</a> : <Link to="/about-us"> About Us</Link>}

                    </li>
                    <li>
                      {pathname == '/personal-info' ? <a onClick={() => handleScreenTop()} className="active">Personal Information</a> : <Link to="/personal-info"> Personal Information</Link>}
                    </li>
                    <li>
                      {pathname == '/terms' ? <a onClick={() => handleScreenTop()} className="active">Terms of Service</a> : <Link to="/terms"> Terms of Service</Link>}
                    </li>

                    <li>
                      {pathname == '/privacy-policy' ? <a onClick={() => handleScreenTop()} className="active">Privacy Policy</a> : <Link to="/privacy-policy"> Privacy Policy</Link>}
                    </li>
                  </ul>
                </div>
              </Col>

              <Col xs={12} sm={12} md={3} lg={3}>
                <div className="footerColumn link">
                  <h4>Resources </h4>
                  <ul>
                    <li>
                      {pathname == '/contact-us' ? <a onClick={() => handleScreenTop()} className="active">Contact Support</a> : <Link to="/contact-us"> Contact Support</Link>}
                    </li>
                  </ul>
                </div>
              </Col>

              <Col xs={12} sm={12} md={3} lg={3}>
                <div className="footerColumn link">
                  <h4>Pricing </h4>

                  <ul>
                    <li>
                      {pathname == '/pricing-plan' ? <a onClick={() => handleScreenTop()} className="active">Plans</a> : <Link to="/pricing-plan"> Plans</Link>}
                    </li>
                    <li>
                      {pathname == '/contact-sales' ? <a onClick={() => handleScreenTop()} className="active">Contact Sales</a> : <Link to="/contact-sales"> Contact Sales</Link>}
                    </li>

                    <li>
                      {pathname == '/blogs' ? <a onClick={() => handleScreenTop()} className="active">Blogs</a> : <Link to="/blogs"> Blogs</Link>}
                    </li>

                    <li>
                      {pathname == '/skipify-u' ? <a onClick={() => handleScreenTop()} className="active">Skipify U</a> : <Link to="/skipify-u"> Skipify U</Link>}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className="newsletter">
              <h4>Newsletter</h4>
              <p>
                Subscribe to our newsletter for the latest updates and
                discounts!
              </p>
              <div className="input_group">
                <input
                  type="text"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button onClick={handleSubmit}>
                  {load ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <IoIosArrowRoundForward />
                  )}{" "}
                </button>
              </div>
              {successMessage && (
                <span className="text-success">{successMessage}</span>
              )}
              {queryErr ||
                (emailErr && (
                  <span className="text-danger">
                    {queryErr} {emailErr}
                  </span>
                ))}
            </div>
          </Col>
        </Row>

        <Row className="mt-5 mb-4">
          <Col xs={12} sm={12} md={3} lg={2}>
            <Link to="/">
              <img src={logoFooter} alt="" className="img-fluid" />
            </Link>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={7}
            lg={8}
            className="d-flex justify-content-between"
          >
            <ul className="footerLinks-copy">
              {/* <li>
                <Link to="/personal-info">Personal Information</Link>
              </li> */}
              {/* plego-white.png */}

              <li>
                <span>© {new Date().getFullYear()} <a href="https://skipify.ai/"> Skipify.ai</a> . All Rights Reserved.</span>
              </li>
              <li>
                <span>Powered by</span>

                <a
                  href="https://www.plego.com/"
                  target="_blank"
                  className="powerdby"
                >
                  Plego Technologies
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={12} md={2} lg={2} className="d-flex justify-content-center align-items-center">
            <a
              href="https://www.plego.com/"
              target="_blank"
              className="powerdby"
            >
              <img src={require('./../assets/images/plego-white.png')} className="img-fluid" alt="skipify logo" />
            </a>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer