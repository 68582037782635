import React from 'react'
import { STRAPI_IMAGE } from '../../../../constants/strapi-api-constant'
import { Card, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
const BlogItem = ({ blog }) => {
  const history = useNavigate()
  const handleRedirect = () => {
    localStorage.removeItem('blogSeo')
    localStorage.setItem('blogSeo', JSON.stringify(blog?.attributes?.Seo))
    history(`/blog/${blog?.attributes?.slug}`)
  }
  return (
    <Card className='blog-card'>
      <Card.Img variant="top" src={STRAPI_IMAGE + blog?.attributes?.Image?.data?.attributes?.url} alt={blog?.attributes?.ImageAlt} />
      <Card.Body>
        <Card.Title>{blog?.attributes?.Category}</Card.Title>
        <h5 onClick={() => handleRedirect()}>{blog?.attributes?.Title}</h5>
        <Button variant="default" onClick={handleRedirect}>Read More</Button>
      </Card.Body>
    </Card>
  )
}

export default BlogItem