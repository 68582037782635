
import { useEffect, useState } from "react";
import HomeMainHeader from "./section/HomeMainHeader";
import HomeTopSection from "./section/HomeTopSection";
import HomeMiddleSection from "./section/HomeMiddleSection";
import HomeMainBottomSection from "./section/HomeMainBottomSection";
import WhyChoseUs from "./section/WhyChoseUs";
import Blogs from "./section/Blogs";
import Review from "./section/Review";
import Seo from './../../components/Seo'
import { StripeAxios, SEO_CONTENT_OF_HOME_PAGE } from "../../constants/strapi-api-constant";
const Home = () => {

  const [metaData, setMetaData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    const authResult = new URLSearchParams(window.location.search);
    getHomePageSeoContent()
    const referralQS = authResult.get('ref');
    if (referralQS) {
      localStorage.setItem('affiliate_id', referralQS);
    }
  }, []);


  const getHomePageSeoContent = async () => {
    try {
      // setLoading(true)
      await StripeAxios.get(SEO_CONTENT_OF_HOME_PAGE).then(res => {
        const { data } = res.data;
        setMetaData(data?.attributes?.Seo)

      }).catch(err => {
        console.log('Home page Seo Api call', err)
      });

    } catch (error) {
      console.log("Home page Seo Api call", error);
    }
  }

  return (
    <>
      <Seo metaData={metaData} />
      <HomeMainHeader />
      {/* <HomeTopSection /> */}
      <WhyChoseUs />
      <Review />
      <HomeMiddleSection />
      <HomeMainBottomSection />
      <Blogs />
    </>
  );
};

export default Home;
