import React, { useEffect, useState } from "react";
import TopAboutSection from "./aboutsection/TopAboutSection";
import MissionVession from "./aboutsection/MissionVession";
import WhyChooseUs from "./aboutsection/WhyChooseUs";
import Faqs from "./aboutsection/Faqs";
import { StripeAxios, SEO_CONTENT_OF_ABOUT_US } from "../../constants/strapi-api-constant";
import Seo from "../../components/Seo";

const AboutUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    getAboutSeoContent()
  }, []);

  const [metaData, setMetaData] = useState({});

  const getAboutSeoContent = async () => {
    try {
      // setLoading(true)
      await StripeAxios.get(SEO_CONTENT_OF_ABOUT_US).then(res => {
        const { data } = res.data;
        setMetaData(data?.attributes?.Seo)
      }).catch(err => {
        console.log('About page Seo Api call', err)
      });
    } catch (error) {
      console.log("About page Seo Api call", error);
    }
  }


  return (
    <>
      <Seo metaData={metaData} />
      <TopAboutSection />
      <MissionVession />
      <WhyChooseUs />
      {/* <Faqs /> */}
    </>
  );
};

export default AboutUs;
