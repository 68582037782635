import React from 'react'
import { Helmet } from 'react-helmet';


const Seo = ({ metaData }) => {
  return (
    <Helmet>
      <title>{metaData?.metaTitle}</title>
      <meta name="title" content={metaData?.metaTitle} />
      <meta name="description" content={metaData?.metaDescription} />
      <meta name="keywords" content={metaData?.keywords} />
      <link rel="canonical" href={metaData?.canonicalURL} />
      {/* OG Tags */}
      <meta property="og:title" content={metaData?.metaTitle} />
      <meta property="og:url" content={metaData?.canonicalURL} />
      <meta property="og:image" content={metaData?.ogImageUrl} />
      <meta property="og:description" content={metaData?.metaDescription} />
    </Helmet>
  )
}

export default Seo