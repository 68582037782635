

import React, { useEffect, useState } from "react";
import { Spinner, Form, Alert, Row, Col } from "react-bootstrap";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Google_reCaptcha_Site_Key,
} from "../../constants/api-credentials.constant";
import { CONTACT_SALES_ADD_ENDPOINT } from "../../constants/api-routes.constant";
import { STRAPI_CONTACT_SALE, STRAPI_SUBMIT_CONTACT_SALE, StripeAxios, SEO_CONTENT_OF_CONTACT_SALE } from "../../constants/strapi-api-constant";
import Loader from "../../components/Loader";
import HubspotTracking from "../../components/HubspotTracking";
import Seo from './../../components/Seo'

const recaptchaRef = React.createRef();

const ContactSale = () => {
  const [values, setValues] = useState({
    fullName: "",
    emailAddress: "",
    phoneNumber: "",
    phoneNumberWithMaking: "",
    message: "",
    companyName: '',
  });
  const [fullName, setfullName] = useState("");
  const [emailAddress, setemailAddress] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [company, setCompany] = useState("");
  const [message, setmessage] = useState("");
  const [load, setLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [queryErr, setqueryErr] = useState("");

  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(false)
  const [metaData, setMetaData] = useState({});

  // const getPageContent = async () => {
  //   setLoading(true)
  //   await StripeAxios.get(STRAPI_CONTACT_SALE)
  //     .then(res => {
  //       setLoading(false)
  //       const { data } = res.data;
  //       setContent({
  //         BannerTitle: data.attributes.BannerTitle,
  //         BannerDescription: data.attributes.BannerDescription,
  //         Heading: data.attributes.Heading,
  //         description: data.attributes.Description,
  //       })
  //     })
  //     .catch(err => { setLoading(false); console.log("contact support", err) })
  // }

  const getPageContent = async () => {
    setLoading(true)

    try {

      const getContactSaleContent = await StripeAxios.get(STRAPI_CONTACT_SALE);
      const getContactSaleSeoContent = await StripeAxios.get(SEO_CONTENT_OF_CONTACT_SALE);

      const [getContactSaleSeoContentResp, getContactSaleContentResp] = await Promise.all([
        getContactSaleSeoContent,
        getContactSaleContent
      ]);
      setLoading(false)


      if (getContactSaleContentResp && getContactSaleContentResp?.data) {
        const { data } = getContactSaleContentResp?.data;
        setContent({
          BannerTitle: data.attributes.BannerTitle,
          BannerDescription: data.attributes.BannerDescription,
          Heading: data.attributes.Heading,
          description: data.attributes.Description,
        })
      }

      if (getContactSaleSeoContentResp && getContactSaleSeoContentResp?.data) {

        const { data } = getContactSaleSeoContentResp?.data;
        setMetaData(data?.attributes?.Seo || {});
      }
    } catch (error) {
      setLoading(false);
      console.log("contact sale Strapi Call for content", error)
    }
  }




  const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;


  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoad(true);
    const token = await recaptchaRef.current.executeAsync();
    if (!token) {
      setqueryErr(`Error: Invalid captcha attempt.`);
      setLoad(false);
      return;
    } else {
      recaptchaRef.current.reset();
    }

    let errorFlag = false;
    if (!values?.fullName) {
      setfullName("Please Enter Full Name");
      setLoad(false);
      errorFlag = true;
    } else {
      setfullName("");
    }

    if (!values?.emailAddress) {
      setemailAddress("Please Enter Email Address");
      setLoad(false);
      errorFlag = true;
    } else if (!EmailRegex.test(values?.emailAddress)) {
      setemailAddress("Enter a valid email address");
      setLoad(false);
      errorFlag = true;
    } else {
      setemailAddress("");
    }

    if (!values?.message) {
      setmessage("Please Enter Message");
      setLoad(false);
      errorFlag = true;
    } else {
      setmessage("");
    }

    if (!errorFlag) {

      try {
        // All validations passed, proceed with API calls
        const submitPersonalInfoPromise = fetch(CONTACT_SALES_ADD_ENDPOINT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName: values?.fullName,
            email: values?.emailAddress,
            phoneNumber: parseInt(values?.phoneNumber),
            message: values?.message,
            companyName: values?.companyName,
          }),
        });

        const handleCmsContentPromise = handleCmsContent();

        const [submitPersonalInfoResponse, handleCmsContentResponse] = await Promise.all([
          submitPersonalInfoPromise,
          handleCmsContentPromise,
        ]);

        if (submitPersonalInfoResponse.status === 200) {
          const submitPersonalInfoJson = await submitPersonalInfoResponse.json();
          setLoad(false);
          setSuccessMessage(submitPersonalInfoJson?.result);
          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
          setValues({
            fullName: "",
            emailAddress: "",
            phoneNumber: "",
            phoneNumberWithMaking: "",
            subject: "",
            message: "",
          });
        }

      } catch (error) {
        setLoad(false);
        setqueryErr("Error: Invalid Username or Password provided.");
      }
    }
  };

  //submit content to CMS
  const handleCmsContent = () => {
    const cmsdata = {
      data: {
        Name: values?.fullName,
        Email: values?.emailAddress,
        Phone: parseInt(values?.phoneNumber),
        Subject: values?.subject,
        Message: values?.message,
      }
    }
    StripeAxios.post(STRAPI_SUBMIT_CONTACT_SALE, cmsdata)
      .then(res => {
        setLoading(false)
        return res;
      })
      .catch(err => { setLoading(false); console.log("contact support submit message", err) })
  }

  // On change handle inputs values
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Function to remove the Masking from phone Number
  const NormalizePhoneNumber = (phone) => {
    const numericOnly = phone.replace(/\D/g, "");
    let maskedNumber = "";
    if (numericOnly.length > 0) {
      maskedNumber = `(${numericOnly.slice(0, 3)}`;
    }
    if (numericOnly.length > 3) {
      maskedNumber += `) ${numericOnly.slice(3, 6)}`;
    }
    if (numericOnly.length > 6) {
      maskedNumber += `-${numericOnly.slice(6, 10)}`;
    }
    setValues({
      ...values,
      phoneNumber: numericOnly.slice(0, 10),
      phoneNumberWithMaking: maskedNumber,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageContent()
  }, []);

  return (
    <>
      <HubspotTracking />
      <Seo metaData={metaData} />
      {loading && <Loader />}
      <header className="header-inner">
        <h1>{content.BannerTitle}</h1>
        <p>{content.BannerDescription}</p>
      </header>
      <section className="contact-page">
        <div className="container">
          <Row className="d-flex justify-content-between">
            <Col md={12} lg={5} className="mb-3">
              <div className="contact_text">
                <h3>{content.Heading}</h3>

                {content?.description?.map((text, d) => {
                  if (text.type == 'paragraph') {
                    return <p key={d}>{text.children[0].text}</p>
                  } else {
                    return <ul key={d}>
                      {text?.children?.map((li, i) => (
                        <li key={i}>{li.children[0].text}</li>
                      ))}
                    </ul>
                  }
                })}
              </div>
            </Col>
            <Col md={12} lg={6} className="px-5 mx-auto">
              <div className="left-contact2">
                {/* <span className="top_label">Send Us Message</span> */}
                {/* <h3>We’re here to help.</h3> */}
                {/* <p>Fill out the form below to get in touch with our support team.</p> */}

                <Row>
                  <Col md={12}>
                    {successMessage != "" ? <Alert variant="success">{successMessage} </Alert> : ""}
                    {queryErr != "" ? <Alert variant="danger">{queryErr} </Alert> : ""}
                  </Col>
                </Row>
                <form onSubmit={handleSubmit} method="POST" id="skipify_contact_sale_form">
                  <p className="require_span">Fields marked with an * are required</p>
                  <Row>
                    <Col md={6}>
                      <input
                        type="text"
                        name="fullName"
                        id="full_name"
                        placeholder="Full Name*"
                        value={values?.fullName}
                        onChange={(e) => {
                          handleChange(e);
                          setfullName("");
                        }}
                      />
                      {fullName ? (
                        <span className="text-danger">
                          <BsFillExclamationCircleFill /> {fullName}
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col md={6}>
                      <input
                        type="email"
                        name="emailAddress"
                        placeholder="Email Address*"
                        value={values?.emailAddress}
                        id="email_address"
                        onChange={(e) => {
                          handleChange(e);
                          setemailAddress("");
                        }}
                      />
                      {emailAddress ? (
                        <span className="text-danger">
                          <BsFillExclamationCircleFill /> {emailAddress}
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="text"
                          name="phoneNumber"
                          id="phone_number"
                          placeholder="Phone # (123) 234-3453"
                          onChange={(e) => {
                            NormalizePhoneNumber(
                              e.target.value.replace(/\D/g, "")
                            );
                            setphoneNumber("");
                          }}
                          value={values?.phoneNumberWithMaking}
                        />
                        {/* {phoneNumber ? <span className="text-danger"><BsFillExclamationCircleFill /> {phoneNumber}</span> : ""} */}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <input
                        type="text"
                        name="company"
                        id="company_name"
                        placeholder="Company Name *"
                        value={values?.subject}
                        required
                        onChange={(e) => {
                          handleChange(e);
                          setCompany("");
                        }}
                      />
                      {/* {subject ? <span className="text-danger"><BsFillExclamationCircleFill /> {subject}</span> : ""} */}
                    </Col>

                    <Col md={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          rows={8}
                          value={values?.message}
                          id="message"
                          onChange={(e) => {
                            handleChange(e);
                            setmessage("");
                          }}
                          name="message"
                          placeholder="Message For Sales Team*"
                        />
                      </Form.Group>
                      {message ? (
                        <span className="text-danger">
                          <BsFillExclamationCircleFill /> {message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>

                  <button type="submit" className="submit">
                    {load ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Send Message"
                    )}
                  </button>
                </form>

                {/* recaptcha */}
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={Google_reCaptcha_Site_Key}
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>

    </>
  );
};

export default ContactSale;
