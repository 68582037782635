import React, { useEffect, useState } from 'react'
import AffiliateTop from './section/AffiliateTop'
import Highlights from './section/Highlights'
import HowShouldJoin from './section/HowShouldJoin'
import AffiliateRequiment from './section/AffiliateRequiment'
import AffiliateForm from './section/AffiliateForm'
import AffiliateFAQ from './section/AffiliateFAQ'
import { SEO_CONTENT_OF_AFFILIATE, StripeAxios } from '../../constants/strapi-api-constant'
import Seo from '../../components/Seo'
import Loader from '../../components/Loader'


const Affiliate = () => {
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getSeoContent()
  }, []);

  const getSeoContent = async () => {
    try {
      setLoading(true)
      await StripeAxios.get(SEO_CONTENT_OF_AFFILIATE).then(res => {
        setLoading(false)
        const { data } = res?.data
        setMetaData(data?.attributes?.Seo);

      }).catch(err => { setLoading(false); console.log('seo affiliate', err) });

    } catch (error) {
      setLoading(false)
      console.log("affiliate seo", error);
    }

  }
  return (
    <>
      {loading && <Loader />}
      <Seo metaData={metaData} />
      <AffiliateTop />
      <Highlights />
      <HowShouldJoin />
      <AffiliateRequiment />
      <AffiliateForm />
      <AffiliateFAQ />
    </>
  )
}

export default Affiliate