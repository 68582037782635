import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { STRAPI_TERMS, StripeAxios, SEO_CONTENT_OF_TERMS } from '../../constants/strapi-api-constant'
import Loader from '../../components/Loader'
import Seo from '../../components/Seo'


const TermsCondition = () => {

  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Terms of Service - ${process.env.REACT_APP_NAME}`;
    handleCallApi()
  }, []); const handleCallApi = async () => {

    await Promise.all([
      getPageContent(),
      getSeoContent()

    ])
  }

  const getSeoContent = async () => {
    try {
      setLoading(true)
      await StripeAxios.get(SEO_CONTENT_OF_TERMS).then(res => {
        setLoading(false)
        const { data } = res?.data
        setMetaData(data?.attributes?.Seo);

      }).catch(err => { setLoading(false); console.log('seo terms', err) });

    } catch (error) {
      setLoading(false)
      console.log("terms seo", error);
    }

  }



  const getPageContent = async () => {
    try {
      setLoading(true)
      await StripeAxios.get(STRAPI_TERMS).then(res => {
        setLoading(false)
        const { data } = res.data;
        setContent(data)
      })
        .catch(err => { setLoading(false); console.log("Terms", err) })

    } catch (error) {
      setLoading(false)
      console.log("api call err", error)
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Seo metaData={metaData} />
      <header className="header-inner">
        <h1>Terms of Service Agreement</h1>
      </header>

      <div className='terms_and_condition'>

        <Row className='pb-4'>
          <Col sm={12} md={10} lg={8} className='mx-auto'>


            {content?.map((item, i) => (
              <div className='term_box' key={i}>
                <h4>{i + 1 + '. '} {item?.attributes?.Heading}</h4>
                {item?.attributes?.Description.map((des, d) => {
                  if (des?.type === "paragraph") {
                    return (<p>{des.children[0].text}</p>)
                  } else {
                    return (
                      <ul>
                        {des?.children?.map((list, l) => (
                          <li key={l}>{list?.children[0].text}</li>
                        ))}
                      </ul>
                    )
                  }
                })}
              </div>
            ))}


          </Col>
        </Row>

      </div>
    </>
  )
}

export default TermsCondition