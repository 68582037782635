import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Container } from 'react-bootstrap'
import { StripeAxios, AFFILIATE_REQUIRMENT_SECTION, STRAPI_IMAGE } from '../../../constants/strapi-api-constant'
import Loader from '../../../components/Loader'
import { AiFillCheckSquare } from "react-icons/ai";

const AffiliateRequiment = () => {
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState({})
  const [lists, setLists] = useState([]);

  useEffect(() => {
    getAffiliateRequirementContent()
  }, [])

  const getAffiliateRequirementContent = async () => {
    try {
      setLoading(true)
      await StripeAxios.get(AFFILIATE_REQUIRMENT_SECTION).then(res => {
        setLoading(false)
        const { data } = res?.data;
        setContent(data?.attributes)
        setLists(data?.attributes?.List)
      })
        .catch(err => {
          console.log("Affiliate Requirment section api error", err)
        });

    } catch (error) {
      setLoading(false)
      console.log("Affiliate Requirment section error", error);
    }
  }
  return (
    <>
      {loading && <Loader />}
      <div className='affiliate_should_join skybg'>
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2 }} xl={{ span: 6, order: 1 }} className='flexStart'>
              <div className='radius_image_design requirements' style={{ background: `url(${STRAPI_IMAGE + content?.Image?.data?.attributes?.url}` }}></div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} xl={{ span: 6, order: 2 }}>
              <div className='affiliate_should_text'>
                <h4 className='affiliate_highlights_text_head'>{content?.Heading}</h4>
                <p >{content?.Description}</p>
                <ul>
                  {lists?.map((list, l) => (
                    <li key={l}>
                      {!list?.Icon &&
                        <div className='icon'><AiFillCheckSquare /></div>
                      }
                      <div className='list_text'>
                        <h4 className='list_text_head'>{list?.Heading}</h4>
                        <p className='list_text_des'>{list?.Description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <a href='#affialte' className='btn-primary d-inline mb-4'> Apply Now</a>
            </Col>

          </Row>
        </Container>

      </div>
    </>

  )
}

export default AffiliateRequiment