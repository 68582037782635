import React from 'react'
import NotFoundSvg from './../assets/images/no-data.svg';
import { LazyLoadImage } from "react-lazy-load-image-component";
const NoRecordsFound = () => {
  return (
    <div className="not_found_record">

      <LazyLoadImage
        src={NotFoundSvg}
        effect="blur"
        alt="Not Found"
      />
      <h4>No Records Found</h4>

    </div>
  )
}

export default NoRecordsFound